import microscope from "../assets/microscope.jpg";
import slide1 from "../assets/images/slide1.JPG";
import slide2 from "../assets/images/slide2.JPG";
import slide4 from "../assets/images/slide4.jpg";

export const homePage = [
  {
    src: slide1,
    text: "Providing prompt, innovative and quality medical diagnostic services.",
    desc: "• Accuracy • Reliability • Convenience",
  },
  {
    src: slide2,
    text: "Our medical laboratory services deliver unwavering accuracy, advanced diagnostics, and unparalleled expertise",
  },
  {
    src: microscope,
    text: "Unlocking the power of visualization to provide actionable intel",
  },
  {
    src: slide4,
    text: "With our client-centric approach, we strive to exceed expectations, deliver personalized solutions",
  },
];
