export const corporateData = [
  {
    id: 1,
    text: "Occupational health screening",
  },
  {
    id: 2,
    text: "Basic Domestic Staff",
  },
  {
    id: 3,
    text: "Standard Domestic Staff",
  },
  {
    id: 4,
    text: "Pre employment tests",
  },
  {
    id: 5,
    text: "Food handlers",
  },
  {
    id: 6,
    text: "Cardiac Screening",
  },
  {
    id: 7,
    text: "Prenuptial Screening",
  },
  {
    id: 8,
    text: "Cervical Screening",
  },
];

export const individualData = [
  {
    name: "Cardiac Screen - mini",
    price: "42,500",
    items: [
      "Chest X-RAY",
      "Creatine Kinase-MB (CK-MB)",
      "Lipid Profile (Lipogram)",
      "Kidney Function Test",
      "Troponin-T",
      "D – Dimer",
    ],
  },
  {
    name: "Cardiac Screen - maxi",
    price: "73,000",
    items: [
      "Chest X-RAY",
      "Creatine Kinase-MB (CK-MB)",
      "Lipid Profile (Lipogram)",
      "ECG and ECHO",
      "Troponin-T",
      "D – Dimer",
    ],
  },
  {
    name: "Blue-day Package",
    price: "19,200",
    items: [
      "Erythrocyte Sedimentation Rate (ESR)",
      "Full Blood Count",
      "Malaria (Thick and Thin Films)",
      "WIDAL (Typhoid Test)",
      "Stool Microscopy",
      "Urinalysis",
      "Sputum AFB - Tuberculosis test",
    ],
  },
  {
    name: "Social package",
    price: "34,500",
    items: [
      "Full Blood Count",
      "Malaria (Thick and Thin Films)",
      "WIDAL (Typhoid Test)",
      "Urinalysis",
      "Hepatitis C Virus Ab (Rapid)",
      "Hepatitis B Surface Antigen (HBsAg) Rapid",
      "HIV I & II Rapid",
      "Syphilis Screening",
      "Neisseria Gonorrhea Rapid (Detection)",
    ],
  },
  {
    name: "Vitality package (Basic)",
    price: "23,900",
    items: [
      "Fasting Blood Sugar (FBS)",
      "Total Cholesterol",
      "Full Blood Count",
      "Urinalysis",
      "Liver Function Test (AST/ALT)",
      "Kidney Function Test (Urea/Creatinine)",
    ],
  },
  {
    name: "Emerald package (mini)",
    price: "54,900",
    items: [
      "Fasting Blood Sugar (FBS)",
      "Lipid Profile (Lipogram)",
      "Uric Acid",
      "Full Blood Count",
      "Thyroid Stimulating Hormone (TSH)",
      "Stool Microscopy",
      "Urinalysis",
      "Liquid Based Cytology (LBC)",
      "Liver Function Test (AST/ALT)",
      "Kidney Function Test (Urea/Creatinine)",
    ],
  },

  {
    name: "Emerald package (maxi)",
    price: "128,790",
    items: [
      "HbA1C",
      "Inorganic Phosphate",
      "Lipid Profile (Lipogram)",
      "Liver Function Test",
      "Kidney Function Test",
      "Calcium",
      "Uric Acid",
      "Thyroid Function Test (Free T3, Free T4, TSH)",
      "Full Blood Count",
      "C-Reactive Protein (CRP)",
      "Hepatitis B Surface Antigen (HBsAg) Rapid",
      "HIV I & II Rapid",
      "Hepatitis C Virus Ab (Rapid)",
      "Stool Occult Blood",
      "Stool Microscopy",
      "Urinalysis",
      "Abdominal Sonography",
      "ECG",
      "CXR",
      "Liquid Based Cytology (LBC)",
    ],
  },
  {
    name: "Emerald fertility profile (mini)",
    price: "45,600",
    items: [
      "Estradiol",
      "Follicule Stimulating Hormone (FSH)",
      "Luteinizing Hormone (LH)",
      "Prolactin",
      "Progesterone (Day 3)",
    ],
  },
  {
    name: "Emerald fertility profile (maxi)",
    price: "93,200",
    items: [
      "Thyroid Stimulating Hormone (TSH)",
      "Estradiol",
      "Follicule Stimulating Hormone (FSH)",
      "Luteinizing Hormone (LH)",
      "Progesterone (Day 3)",
      "Prolactin",
      "Anti Mullerian Hormone (AMH)",
      "Progesterone (Day 21)",
      "Abdominopelvic ultrasound",
    ],
  },
  {
    name: "PCOS(mini)",
    price: "32,400",
    items: [
      "Fasting Blood Sugar (FBS)",
      "Total Cholesterol",
      "Follicule Stimulating Hormone (FSH)",
      "Luteinizing Hormone (LH)",
      "Testosterone (Total)",
      "Ultrasound Transvaginal",
    ],
  },
  {
    name: "PCOS(maxi)",
    price: "106,650",
    items: [
      "Fasting Blood Sugar (FBS)",
      "Thyroid Stimulating Hormone (TSH)",
      "Estradiol",
      "Follicule Stimulating Hormone (FSH)",
      "Luteinizing Hormone (LH)",
      "Prolactin",
      "SHBG (Sex Hormone Binding Globulin)",
      "Dehydroepiandrosterone Sulphate (DHEAS)",
      "Free Androgen Index (FAI)",
      "Testosterone (Total)",
      "Transvaginal Ultrasound",
    ],
  },
  {
    name: "Cervical Cancer Screening",
    price: "14,580",
    items: ["Liquid Based Cytology (LBC)"],
  },
  {
    name: "Topaz fertility profile (mini)",
    price: "21,300",
    items: [
      "Seminal Fluid Analysis (SFA)",
      "Semen: Microscopy, Culture & Sensitivity (MCS)",
    ],
  },
  {
    name: "Topaz fertility profile (maxi)",
    price: "52,900",
    items: [
      "Follicule Stimulating Hormone (FSH)",
      "Luteinizing Hormone (LH)",
      "Prolactin",
      "Testosterone (Total)",
      "Semen: Microscopy, Culture & Sensitivity (MCS)",
    ],
  },
  {
    name: "Topaz package (mini)",
    price: "52,500",
    items: [
      "Fasting Blood Sugar (FBS)",
      "Lipid Profile (Lipogram)",
      "Uric Acid",
      "Full Blood Count",
      "Thyroid Stimulating Hormone (TSH)",
      "Prostate Specific Antigen (PSA)",
      "Stool Microscopy",
      "Urinalysis",
      "Liver Function Test (AST/ALT)",
      "Kidney Function Test (Urea/Creatinine)",
    ],
  },
  {
    name: "Topaz package (maxi)",
    price: "125,680",
    items: [
      "HbA1C",
      "Inorganic Phosphate",
      "Lipid Profile (Lipogram)",
      "Liver Function Test",
      "Kidney Function Test",
      "Calcium",
      "Uric Acid",
      "Thyroid Function Test (Free T3, Free T4, TSH)",
      "Full Blood Count",
      "Prostate Specific Antigen (PSA)",
      "C-Reactive Protein (CRP)",
      "Hepatitis B Surface Antigen (HBsAg) Rapid",
      "HIV I & II Rapid",
      "Hepatitis C Virus Ab (Rapid)",
      "Stool Occult Blood",
      "Stool Microscopy",
      "CXR",
      "ECG",
      "Abdominal ultrasound",
      "Urinalysis",
    ],
  },
];

export const conventionalPackages = [
  {
    name: "BASIC",
    tests: [
      "Consultation/Physical examination",
      "Full Blood Count",
      "ESR",
      "Urine Routine",
      "Stool Routine",
      "HIV Screening",
      "Hepatitis B Screening",
      "Hepatitis C Screening",
      "Fasting Blood Sugar",
      "Renal Profile (Electrolytes/Urea/Creatinine, eGFR)",
      "Liver Profile (SGOT (AST), SGPT (ALT), GGTP, Bilirubin, ALP, Proteins, Albumin, Globulin, A/G ratio)",
      "Total Cholesterol",
      "Thyroid Panel (FT3 + FT4 + TSH)",
    ],
    price: "64,400",
  },
  {
    name: "SILVER",
    tests: [
      "Consultation/Physical examination",
      "Full Blood Count",
      "ESR",
      "Urine Routine",
      "Stool Routine",
      "HIV Screening",
      "Hepatitis B Screening",
      "Hepatitis C Screening",
      "Fasting Blood Sugar",
      "Renal Profile (Electrolytes/Urea/Creatinine, eGFR)",
      "Liver Profile (SGOT (AST), SGPT (ALT), GGTP, Bilirubin, ALP, Proteins, Albumin, Globulin, A/G ratio)",
      "Total Cholesterol",
      "Thyroid Panel (FT3 + FT4 + TSH)",
      "Lipid Profile (Total Cholesterol, LDL, HDL, VLDL, Triglycerides)",
      "Uric Acid",
      "Calcium",
      "Phosphorus",
      "HBa1C",
      "Micro albumin",
      "ECG",
      "Chest X-ray (Digital)",
      "Abdominopelvic Ultrasound Scan",
      "Breast Scan/Mammography",
      "PAP smear",
      "PSA",
      "Spirometry",
    ],
    price: "204,000",
  },
  {
    name: "GOLD",
    tests: [
      "Consultation/Physical examination",
      "Full Blood Count",
      "ESR",
      "Urine Routine",
      "Stool Routine",
      "HIV Screening",
      "Hepatitis B Screening",
      "Hepatitis C Screening",
      "Fasting Blood Sugar",
      "Renal Profile (Electrolytes/Urea/Creatinine, eGFR)",
      "Liver Profile (SGOT (AST), SGPT (ALT), GGTP, Bilirubin, ALP, Proteins, Albumin, Globulin, A/G ratio)",
      "Total Cholesterol",
      "Thyroid Panel (FT3 + FT4 + TSH)",
      "Lipid Profile (Total Cholesterol, LDL, HDL, VLDL, Triglycerides)",
      "Uric Acid",
      "Calcium",
      "Phosphorus",
      "HBa1C",
      "Micro albumin",
      "ECG",
      "Chest X-ray (Digital)",
      "Abdominopelvic Ultrasound Scan",
      "Breast Scan/Mammography",
      "PAP smear",
      "PSA",
      "Spirometry",
      "Helicobacter Pylori (Stool)",
      "Tropinin T",
      "CEA",
      "CA 19.9",
      "D Dimer",
    ],
    price: "303,800",
  },
  {
    name: "DIAMOND",
    tests: [
      "Consultation/Physical examination",
      "Full Blood Count",
      "ESR",
      "Urine Routine",
      "Stool Routine",
      "HIV Screening",
      "Hepatitis B Screening",
      "Hepatitis C Screening",
      "Fasting Blood Sugar",
      "Renal Profile (Electrolytes/Urea/Creatinine, eGFR)",
      "Liver Profile (SGOT (AST), SGPT (ALT), GGTP, Bilirubin, ALP, Proteins, Albumin, Globulin, A/G ratio)",
      "Total Cholesterol",
      "Thyroid Panel (FT3 + FT4 + TSH)",
      "Lipid Profile (Total Cholesterol, LDL, HDL, VLDL, Triglycerides)",
      "Uric Acid",
      "Calcium",
      "Phosphorus",
      "HBa1C",
      "Micro albumin",
      "ECG",
      "Chest X-ray (Digital)",
      "Abdominopelvic Ultrasound Scan",
      "Breast Scan/Mammography",
      "PAP smear",
      "PSA",
      "Spirometry",
      "Helicobacter Pylori (Stool)",
      "Tropinin T",
      "CEA",
      "CA 19.9",
      "D Dimer",
      "Prostate (TRUSS)/LBC",
    ],
    price: "320,000",
  },
  {
    name: "PLATINUM",
    tests: [
      "Consultation/Physical examination",
      "Full Blood Count",
      "ESR",
      "Urine Routine",
      "Stool Routine",
      "HIV Screening",
      "Hepatitis B Screening",
      "Hepatitis C Screening",
      "Fasting Blood Sugar",
      "Renal Profile (Electrolytes/Urea/Creatinine, eGFR)",
      "Liver Profile (SGOT (AST), SGPT (ALT), GGTP, Bilirubin, ALP, Proteins, Albumin, Globulin, A/G ratio)",
      "Total Cholesterol",
      "Thyroid Panel (FT3 + FT4 + TSH)",
      "Lipid Profile (Total Cholesterol, LDL, HDL, VLDL, Triglycerides)",
      "Uric Acid",
      "Calcium",
      "Phosphorus",
      "HBa1C",
      "Micro albumin",
      "ECG",
      "Chest X-ray (Digital)",
      "Abdominopelvic Ultrasound Scan",
      "Breast Scan/Mammography",
      "PAP smear",
      "PSA",
      "Spirometry",
      "Helicobacter Pylori (Stool)",
      "Tropinin T",
      "CEA",
      "CA 19.9",
      "D Dimer",
      "Prostate (TRUSS)/LBC",
      "Echocardiography",
    ],
    price: "360,550",
  },
];

export const nigeriaStates = [
  {
    name: "Abia",
    capital: "Umuahia",
    region: "South East",
  },
  {
    name: "Adamawa",
    capital: "Yola",
    region: "North East",
  },
  {
    name: "Akwa Ibom",
    capital: "Uyo",
    region: "South South",
  },
  {
    name: "Anambra",
    capital: "Awka",
    region: "South East",
  },
  {
    name: "Bauchi",
    capital: "Bauchi",
    region: "North East",
  },
  {
    name: "Bayelsa",
    capital: "Yenagoa",
    region: "South South",
  },
  {
    name: "Benue",
    capital: "Makurdi",
    region: "North Central",
  },
  {
    name: "Borno",
    capital: "Maiduguri",
    region: "North East",
  },
  {
    name: "Cross River",
    capital: "Calabar",
    region: "South South",
  },
  {
    name: "Delta",
    capital: "Asaba",
    region: "South South",
  },
  {
    name: "Ebonyi",
    capital: "Abakaliki",
    region: "South East",
  },
  {
    name: "Edo",
    capital: "Benin City",
    region: "South South",
  },
  {
    name: "Ekiti",
    capital: "Ado-Ekiti",
    region: "South West",
  },
  {
    name: "Enugu",
    capital: "Enugu",
    region: "South East",
  },
  {
    name: "Gombe",
    capital: "Gombe",
    region: "North East",
  },
  {
    name: "Imo",
    capital: "Owerri",
    region: "South East",
  },
  {
    name: "Jigawa",
    capital: "Dutse",
    region: "North West",
  },
  {
    name: "Kaduna",
    capital: "Kaduna",
    region: "North West",
  },
  {
    name: "Kano",
    capital: "Kano",
    region: "North West",
  },
  {
    name: "Katsina",
    capital: "Katsina",
    region: "North West",
  },
  {
    name: "Kebbi",
    capital: "Birnin Kebbi",
    region: "North West",
  },
  {
    name: "Kogi",
    capital: "Lokoja",
    region: "North Central",
  },
  {
    name: "Kwara",
    capital: "Ilorin",
    region: "North Central",
  },
  {
    name: "Lagos",
    capital: "Ikeja",
    region: "South West",
  },
  {
    name: "Nasarawa",
    capital: "Lafia",
    region: "North Central",
  },
  {
    name: "Niger",
    capital: "Minna",
    region: "North Central",
  },
  {
    name: "Ogun",
    capital: "Abeokuta",
    region: "South West",
  },
  {
    name: "Ondo",
    capital: "Akure",
    region: "South West",
  },
  {
    name: "Osun",
    capital: "Osogbo",
    region: "South West",
  },
  {
    name: "Oyo",
    capital: "Ibadan",
    region: "South West",
  },
  {
    name: "Plateau",
    capital: "Jos",
    region: "North Central",
  },
  {
    name: "Rivers",
    capital: "Port Harcourt",
    region: "South South",
  },
  {
    name: "Sokoto",
    capital: "Sokoto",
    region: "North West",
  },
  {
    name: "Taraba",
    capital: "Jalingo",
    region: "North East",
  },
  {
    name: "Yobe",
    capital: "Damaturu",
    region: "North East",
  },
  {
    name: "Zamfara",
    capital: "Gusau",
    region: "North West",
  },
];
