import afrilife from "../assets/images/afrilife.png";
import paclogo from "../assets/images/paclogo.png";
import philipshmo from "../assets/images/phillipshmo.png";
import ziltch1 from "../assets/images/ziltch1.png";

export const partners = [
  {
    img: afrilife,
  },
  {
    img: paclogo,
  },
  {
    img: philipshmo,
  },
  {
    img: ziltch1,
  },
];
